import { Dropdown, Button, Menu } from 'antd';
import CcxContextualMenuIcon from '../ccx/icons/CcxContextualMenuIcon';
import { CopyOutlined } from '@ant-design/icons';
import DeleteDbParametersPopover from './DeleteDbParametersPopover';
import EditDBParametersModal from './EditDBParametersModal';
import styles from './DbParametersActionsLists.module.less';

interface DbParametersActionsListsProps {
    record: any;
    refresh: () => void | Promise<void>;
}

const DbParametersActionsLists = ({
    record,
    refresh,
}: DbParametersActionsListsProps) => {
    const menu = (
        <Menu className={styles.DbParametersActionsListsMenu}>
            <Menu.Item key="edit" className={styles.DbParametersActionButton}>
                <EditDBParametersModal record={record} refresh={refresh} />
            </Menu.Item>
            <Menu.Item
                key="duplicate"
                className={styles.DbParametersActionButton}
            >
                <Button
                    className={styles.DuplicateDbParametersPopoverButton}
                    icon={<CopyOutlined />}
                >
                    Duplicate
                </Button>
            </Menu.Item>
            <Menu.Item key="delete" className={styles.DbParametersActionButton}>
                <DeleteDbParametersPopover record={record} refresh={refresh} />
            </Menu.Item>
        </Menu>
    );

    return (
        <Dropdown overlay={menu} placement="bottomRight" trigger={['click']}>
            <Button icon={<CcxContextualMenuIcon />} />
        </Dropdown>
    );
};

export default DbParametersActionsLists;
