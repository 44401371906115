import {
    Modal,
    Form,
    Select,
    Row,
    Col,
    Button,
    Typography,
    FormInstance,
    Spin,
} from 'antd';
import CcxLogo from '../../../ccx/common/CcxLogo';
import styles from './DatastoreSettingsDbParameters.module.less';
import useDbParametersGroup from '../../../../core/hooks/useDbParametersGroup';
import { mapDbParameterGroupsToOptions } from '../../../../utils/dbParameterGroups';
import { useState } from 'react';
import DeploymentsItem from '../../../../types/DeploymentsItem';
import { DbParameterGroup } from '../../../../types/DbParameterGroup';

interface AssignGroupModalProps {
    readonly visible: boolean;
    readonly form: FormInstance;
    readonly setVisible: (visible: boolean) => void;
    readonly dataStore: DeploymentsItem | undefined;
    readonly onSubmit: (
        groupSelected: DbParameterGroup,
        dataStoreUUID: string
    ) => void;
}

const AssignGroupModal: React.FC<AssignGroupModalProps> = ({
    visible,
    setVisible,
    form,
    dataStore,
    onSubmit,
}) => {
    const {
        dbParameters: dbParametersGroup,
        loading,
        error,
    } = useDbParametersGroup();

    const [selectedGroup, setSelectedGroup] = useState<
        DbParameterGroup | undefined
    >(undefined);
    const [isSubmitting, setIsSubmitting] = useState(false);

    const handleSubmit = () => {
        if (!dataStore || !selectedGroup) return;

        try {
            setIsSubmitting(true);
            onSubmit(selectedGroup, dataStore.dataStoreUuid);
        } catch (error) {
            console.error('Failed to submit:', error);
        } finally {
            setIsSubmitting(false);
        }
    };

    const mapDbParametersGroup = () => {
        if (!dataStore || !dbParametersGroup?.parameterGroups) return [];
        return mapDbParameterGroupsToOptions(
            dbParametersGroup,
            dataStore.getDatabaseVendor(),
            dataStore.getDatabaseVersion()
        );
    };

    const onSelectDbParametersGroup = (value: string) => {
        if (!dbParametersGroup?.parameterGroups) return;

        const selectedGroup = dbParametersGroup.parameterGroups.find(
            (group: DbParameterGroup) => group.uuid === value
        );

        setSelectedGroup(selectedGroup);
        form.setFieldValue(
            'dbParametersGroup',
            selectedGroup ? value : undefined
        );
    };

    const modalContent = (
        <>
            <Form layout="vertical" form={form}>
                <Form.Item
                    name="dbParametersGroup"
                    label="DB parameter group"
                    rules={[
                        {
                            required: true,
                            message: 'Please select a DB parameter group!',
                        },
                    ]}
                >
                    <Select
                        className={styles.DbParametersGroupSelect}
                        options={mapDbParametersGroup()}
                        onChange={onSelectDbParametersGroup}
                        disabled={loading || isSubmitting}
                        placeholder="Select a parameter group"
                    />
                </Form.Item>
            </Form>
            {selectedGroup && dataStore && (
                <>
                    <Typography.Text>
                        The group will be assigned to:
                    </Typography.Text>
                    <Row className={styles.AddDBParametersButton}>
                        <Col span={4} className={styles.DbParametersSelfCenter}>
                            <CcxLogo
                                logo={dataStore.getDbIconName()}
                                size="large"
                            />
                        </Col>
                        <Col span={20}>
                            <Typography.Text strong>
                                {selectedGroup.name}
                            </Typography.Text>
                            <Typography.Paragraph>
                                Vendor and version:{' '}
                                {selectedGroup.databaseVendor +
                                    ' ' +
                                    selectedGroup.databaseVersion}
                            </Typography.Paragraph>
                        </Col>
                    </Row>
                </>
            )}
        </>
    );

    return (
        <Modal
            open={visible}
            title="Assign DB parameter group"
            onCancel={() => setVisible(false)}
            footer={
                <Row justify="space-between">
                    <Col span={4} className={styles.DbParametersSelfCenter}>
                        <Typography.Text type="danger">*</Typography.Text>
                        Required
                    </Col>
                    <Col span={20}>
                        <Button
                            type="primary"
                            onClick={handleSubmit}
                            disabled={!selectedGroup || loading || isSubmitting}
                            loading={isSubmitting}
                        >
                            Save
                        </Button>
                    </Col>
                </Row>
            }
            closable={!isSubmitting}
            maskClosable={!isSubmitting}
        >
            <Spin spinning={loading}>
                {error ? (
                    <Typography.Text type="danger">
                        Failed to load parameter groups. Please try again later.
                    </Typography.Text>
                ) : (
                    modalContent
                )}
            </Spin>
        </Modal>
    );
};

export default AssignGroupModal;
