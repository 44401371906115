import { useState } from 'react';
import { Button, Form, notification } from 'antd';
import { EditOutlined } from '@ant-design/icons';
import DbParametersModal from './DbParametersModal';
import styles from './EditDbParametersModal.module.less';
import DbParameterGroupService from '../../services/DbParameterGroupService';
import CcxIconCheckCircleTwoTone from '../ccx/icons/CcxIconCheckCircleTwoTone';
import CcxIconCloseCircleTwoTone from '../ccx/icons/CcxIconCloseCircleTwoTone';
import { useParams } from 'react-router';

interface EditDBParametersModalProps {
    record: any;
    refresh?: () => void;
}

interface UrlProps {
    dataStoreUuid: string;
}

const EditDBParametersModal = ({
    record,
    refresh,
}: EditDBParametersModalProps) => {
    const [visible, setVisible] = useState<boolean>(false);
    const [form] = Form.useForm();

    const onSubmit = async (formData: any) => {
        const data: any = {
            name: formData.name,
            database_vendor: formData.databaseVendor,
            database_version: formData.databaseVersion,
            database_type: formData.configuration,
            description: formData.description,
            parameters: formData.dbParameters,
            data_stores: formData.dataStores,
        };
        try {
            await DbParameterGroupService.updateDbParameterGroup(
                record.uuid,
                data
            );
            notification.open({
                message: 'DB Parameter Group Created',
                description:
                    'Database parameter group has been created successfully.',
                icon: <CcxIconCheckCircleTwoTone twoToneColor="#52c41a" />,
            });
            if (refresh) {
                refresh();
            }
            setVisible(false);
        } catch (error) {
            notification.open({
                message: 'Failed to Create DB Parameter Group',
                description: `An error occurred while creating the database parameter group: ${error}`,
                icon: <CcxIconCloseCircleTwoTone twoToneColor="#eb2f96" />,
            });
            console.error(error);
        }
        form.resetFields();
    };

    const handleCancel = () => {
        setVisible(false);
    };

    return (
        <>
            <Button
                className={styles.EditDbParametersPopoverButton}
                icon={<EditOutlined />}
                onClick={() => setVisible(true)}
            >
                Edit
            </Button>
            <DbParametersModal
                visible={visible}
                onSubmit={onSubmit}
                setVisible={setVisible}
                handleCancel={handleCancel}
                operation="edit"
                record={record}
                form={form}
            />
        </>
    );
};

export default EditDBParametersModal;
