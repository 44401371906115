import { DeleteOutlined, InfoCircleFilled } from '@ant-design/icons';
import { Popover, Space, Row, Col, Button, notification, Tooltip } from 'antd';
import { useState } from 'react';
import styles from './DeleteDbParametersPopover.module.less';
import DbParameterGroupService from '../../services/DbParameterGroupService';
import CcxIconCheckCircleTwoTone from '../ccx/icons/CcxIconCheckCircleTwoTone';
import CcxIconCloseCircleTwoTone from '../ccx/icons/CcxIconCloseCircleTwoTone';
import CcxIconInfoCircleOutlined from '../ccx/icons/CcxIconInfoCircleOutlined';

interface DeleteDbParametersPopoverProps {
    record: any;
    testId?: string;
    refresh: () => void | Promise<void>;
}

const DeleteDbParametersPopover = ({
    record,
    refresh,
    testId = 'DeleteDbParametersPopover',
}: DeleteDbParametersPopoverProps) => {
    const [openPopover, setOpenPopover] = useState(false);

    const handleDelete = async () => {
        setOpenPopover(false);
        try {
            await DbParameterGroupService.deleteDbParameterGroup(record.uuid);
            notification.open({
                message: 'Success',
                description: 'Parameter group deleted successfully',
                icon: <CcxIconCheckCircleTwoTone twoToneColor="#52c41a" />,
                duration: 4.5,
            });
            refresh();
        } catch (e) {
            notification.open({
                message: 'Error',
                description: 'Failed to delete parameter group',
                icon: <CcxIconCloseCircleTwoTone twoToneColor="#eb2f96" />,
                duration: 4.5,
            });
            console.error(e);
        }
    };

    return (
        <Popover
            open={openPopover}
            placement="left"
            content={
                <>
                    <Row justify={'end'} className={styles.PopoverContentRow}>
                        <Col span={2}>
                            <InfoCircleFilled
                                className={styles.ExclamationCircleFilledColor}
                            />
                        </Col>
                        <Col span={22}>Are you sure delete this group? </Col>
                    </Row>
                    <Row justify={'end'} className={styles.PopoverActionsRow}>
                        <Space>
                            <Button
                                type="primary"
                                onClick={() => handleDelete()}
                            >
                                Yes
                            </Button>
                            <Button onClick={() => setOpenPopover(false)}>
                                No
                            </Button>
                        </Space>
                    </Row>
                </>
            }
            trigger="click"
            onOpenChange={setOpenPopover}
        >
            <Button
                type="text"
                className={styles.DeleteDbParametersPopoverButton}
                icon={<DeleteOutlined />}
                disabled={!!record?.dataStores?.length}
                onClick={() => setOpenPopover(!openPopover)}
            >
                Delete
            </Button>
            {record.dataStores?.length > 0 && (
                <Tooltip
                    title="The delete option is disabled because this DB parameter group
                is assigned to a data store. To delete it, unassign the group fromall 
                associated data stores."
                >
                    <Button
                        type="text"
                        className={styles.DeleteDbParametersPopoverButton}
                        icon={<CcxIconInfoCircleOutlined />}
                        disabled={!!record?.dataStores?.length}
                        onClick={() => setOpenPopover(!openPopover)}
                    />
                </Tooltip>
            )}
        </Popover>
    );
};

export default DeleteDbParametersPopover;
