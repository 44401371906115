interface ParameterGroup {
    databaseVendor: string;
    databaseVersion: string;
    name: string;
    uuid: string;
}

export interface DbParameterGroups {
    parameterGroups: ParameterGroup[];
}

export const mapDbParameterGroupsToOptions = (
    dbParameterGroups: DbParameterGroups | undefined,
    databaseVendor?: string,
    databaseVersion?: string
): { label: string; value: string }[] => {
    if (!dbParameterGroups) return [];
    return (
        dbParameterGroups.parameterGroups
            ?.filter((group: ParameterGroup) => {
                if (!databaseVendor || !databaseVersion) return true;
                return (
                    group.databaseVendor === databaseVendor &&
                    group.databaseVersion === databaseVersion
                );
            })
            ?.map((group: ParameterGroup) => ({
                label: group.name,
                value: group.uuid,
            })) || []
    );
};
