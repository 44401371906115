import { ReactElement, useState } from 'react';
import { Button, Popconfirm, Tabs, notification } from 'antd';
import { useHistory, useParams } from 'react-router';
import CcxComponentProps from '../../../../core/CcxComponent';
import styles from './DataStoreSettings.module.less';
import CcxIconCheckCircleTwoTone from '../../../ccx/icons/CcxIconCheckCircleTwoTone';
import CcxIconCloseCircleTwoTone from '../../../ccx/icons/CcxIconCloseCircleTwoTone';
import DatabaseConfigurationService from '../../../../services/DatabaseConfigurationService';
import DataStoreSettingsGeneral from './DatastoreSettingsGeneral';
import DatastoreSettingDbParameters from './DatastoreSettingsDbParamters';
import useDbParameters from '../../../../core/hooks/useDbParameters';
import AppLoading from '../../../AppLoading';
import useDataStoreJobs from '../../../../core/hooks/useDataStoreJobs';
import DeploymentsItem from '../../../../types/DeploymentsItem';

interface UrlProps {
    dataStoreUuid: string;
    projectUuid: string;
    tab: string;
}

interface Props extends CcxComponentProps {
    currentDeployment?: DeploymentsItem | null | undefined;
}

function DataStoreSettings({
    currentDeployment,
    testId = 'DataStoreSettings',
}: Props): ReactElement {
    const { dataStoreUuid, projectUuid, tab } = useParams<UrlProps>();
    const history = useHistory();
    const [isEditMode, setIsEditMode] = useState(false);
    const [dbParametersData, setDbParametersData] = useState<any>();
    const { refresh, dbParameters, loading } = useDbParameters(dataStoreUuid);
    const { refresh: refreshJobs } = useDataStoreJobs({
        dataStoreUuid,
    });

    const handleSubmit = async () => {
        if (dbParametersData) {
            try {
                await DatabaseConfigurationService.updateDatabaseParameters(
                    dataStoreUuid,
                    dbParametersData
                );
                refresh && refresh();
                setDbParametersData(null);
                setIsEditMode(false);

                refreshJobs();

                notification.open({
                    message: 'Changes are saved successfully',
                    icon: <CcxIconCheckCircleTwoTone twoToneColor="#46bd15" />,
                });
            } catch (e: any) {
                notification.open({
                    message: 'Error',
                    description: `An error occurred while processing the requests. ${e}`,
                    icon: <CcxIconCloseCircleTwoTone twoToneColor="#eb2f96" />,
                });
            }
        }
    };

    const onChange = (name: string, value: string | boolean) => {
        if (dbParameters?.parameters) {
            const dbParams = JSON.parse(JSON.stringify(dbParameters));

            // Find the index of the parameter with the matching name
            const parameterIndex = dbParams?.parameters?.findIndex(
                (param: { name: string }) => param.name === name
            );

            if (parameterIndex !== -1) {
                // If a parameter with the matching name is found, update its value
                dbParams.parameters[parameterIndex].value = value;
            }
            // Convert array of parameters to an object
            const tempDbParameter = dbParams?.parameters?.reduce(
                (
                    result: { [x: string]: { value: any } },
                    item: { name: string | number; value: any }
                ) => {
                    result[item.name] = { value: item.value };
                    return result;
                },
                {}
            );

            setDbParametersData({
                parameters: tempDbParameter,
            });
        }
    };

    const handleCancel = () => {
        setIsEditMode(false);
        setDbParametersData(null);
    };

    return !currentDeployment ? (
        <AppLoading />
    ) : (
        <Tabs
            data-testid={testId}
            activeKey={tab || 'general'}
            onChange={(tabKey: any) => {
                const tab = tabKey === 'general' ? '' : `/${tabKey}`;
                history.push(
                    `/projects/${projectUuid}/data-stores/${dataStoreUuid}/settings${tab}`
                );
            }}
        >
            <Tabs.TabPane
                tab="General"
                key="general"
                style={{ paddingTop: 20 }}
            >
                <DataStoreSettingsGeneral
                    currentDeployment={currentDeployment}
                />
            </Tabs.TabPane>

            {dbParameters && dbParameters.parameters?.length > 0 && (
                <Tabs.TabPane
                    tab="DB Parameters"
                    key="dbParameters"
                    style={{ paddingTop: 20 }}
                >
                    <DatastoreSettingDbParameters
                        isEditMode={isEditMode}
                        onChange={onChange}
                        dbParameters={dbParameters}
                        loading={loading}
                    />
                </Tabs.TabPane>
            )}
        </Tabs>
    );
}

export default DataStoreSettings;
