import React, { ReactElement, useEffect, useState } from 'react';
import { Checkbox, Select, Form, Input, notification } from 'antd';
import styles from './DatastoreSettingsDbParameters.module.less';
import './DatastoreSettingsDbParameters.less';
import AppTable from '../../../AppTable';
import AppLoading from '../../../AppLoading';
import { Type } from '../../../../types/DbParameter';
import { EditOutlined } from '@ant-design/icons';
import InfoIcon from '@severalnines/bar-frontend-components/build/lib/General/InfoIcon';
import DbParameters from '../../../../types/DbParameters';
import { useAppSelector } from '../../../../redux/hooks';
import ParameterGroupDisplay from './ParameterGroupDisplay';
import AssignGroupModal from './AssignGroupModal';
import DbParameterGroupService from '../../../../services/DbParameterGroupService';
import CcxIconCheckCircleTwoTone from '../../../ccx/icons/CcxIconCheckCircleTwoTone';
import CcxIconCloseCircleTwoTone from '../../../ccx/icons/CcxIconCloseCircleTwoTone';
import useDbParametersGroup from '../../../../core/hooks/useDbParametersGroup';
import DbParametersModal from '../../../dbParameters/DbParametersModal';
import { DbParameter } from '../../../../types/DbParameterGroup';

interface Props {
    isEditMode: boolean;
    onChange: (name: any, value: string | boolean) => void;
    dbParameters: DbParameters | undefined;
    loading: boolean;
}

function DatastoreSettingDbParameters({
    isEditMode,
    onChange,
    loading,
    dbParameters,
}: Props): ReactElement {
    const { Option } = Select;
    const [editableFields, setEditableFields] = useState<any[]>([]);
    const { dataStore } = useAppSelector((state) => state.dataStore);
    const [form] = Form.useForm();
    const [selectedGroup, setSelectedGroup] = useState<any>(undefined);
    const [isLoading, setIsLoading] = useState<boolean>(loading);
    const [isAssignGroupModalVisible, setIsAssignGroupModalVisible] =
        useState<boolean>(false);
    const [isDbParametersModalVisible, setIsDbParametersModalVisible] =
        useState<boolean>(false);
    const [dbParametersTableData, setDbParametersTableData] = useState<
        DbParameter[]
    >([]);
    const [dbParameterForm] = Form.useForm();
    const {
        dbParameters: dbParametersGroup,
        loading: loadingGroup,
        error: errorGroup,
        refresh,
    } = useDbParametersGroup();

    const getFormattedValues = (value: any, isDefault: boolean) => {
        const formattedValue = value
            .split(',')
            .reduce((result: any, item: any, index: number) => {
                const commaCount = result.split(',').length - 1;
                if (result === '' && isDefault) {
                    return `Default: ${item}`;
                } else if (result === '' && !isDefault) {
                    return `${item}`;
                } else if (commaCount % 2 === 1) {
                    return `${result},\n${item}`;
                } else {
                    return `${result},${item}`;
                }
            }, '');

        return formattedValue;
    };

    useEffect(() => {
        if (!isEditMode) {
            setEditableFields([]);
        }
    }, [isEditMode]);

    useEffect(() => {
        setIsLoading(loading || loadingGroup);
    }, [loading, loadingGroup]);

    useEffect(() => {
        if (dataStore && dataStore.parameterGroupId) {
            getAttachedDbParameterGroup(dataStore.getParameterGroupId());
        }
    }, [dataStore, dbParametersGroup]);

    const getAttachedDbParameterGroup = async (groupId: string) => {
        try {
            const attachedGroup =
                await DbParameterGroupService.getSingleDbParameterGroup(
                    groupId
                );
            if (attachedGroup) {
                setSelectedGroup(attachedGroup);
            }
        } catch (error) {
            showNotifications(false, 'Error', 'Failed to get details');
        }
    };

    const getMinMaxDefaultValues = (record: any) => {
        return (
            <div className={styles.DatastoreSettingDbParametersDefault}>
                {record?.type === Type.NUMBER && (
                    <>
                        <span>{`Max: ${record?.max} | `}</span>
                        <span>{`Min: ${record?.min} | `}</span>
                    </>
                )}
                {record?.defaultValue && (
                    <span>
                        {getFormattedValues(record?.defaultValue, true)}
                    </span>
                )}
            </div>
        );
    };

    function renderInputField(record: any) {
        switch (record?.type) {
            case Type.BOOLEAN:
                return (
                    <Checkbox
                        defaultChecked={
                            record?.value
                                ? JSON.parse(record?.value)
                                : JSON.parse(record?.defaultValue)
                        }
                        onChange={(e) =>
                            onChange(record?.name, e.target.checked)
                        }
                    />
                );
            case Type.SELECT:
                return (
                    <Select
                        defaultValue={
                            record?.value ? record?.value : record?.defaultValue
                        }
                        className={styles.DatastoreSettingDbParametersSelect}
                        onChange={(value) => onChange(record?.name, value)}
                    >
                        {record?.options?.map((item: any, index: number) => {
                            return (
                                <Option value={item} key={`${index}`}>
                                    {item}
                                </Option>
                            );
                        })}
                    </Select>
                );
            case Type.NUMBER:
                return (
                    <Form.Item
                        name={record?.name}
                        className={styles.DatastoreSettingDbParametersField}
                    >
                        <Input
                            type="number"
                            autoComplete="off"
                            min={record?.min}
                            max={record?.max}
                            defaultValue={
                                record?.value
                                    ? record?.value
                                    : record?.defaultValue
                            }
                            onChange={(e) =>
                                onChange(record?.name, e.target.value)
                            }
                        />
                    </Form.Item>
                );
            case Type.TEXT:
                return (
                    <Form.Item
                        name={record?.name}
                        className={styles.DatastoreSettingDbParametersField}
                    >
                        <Input.TextArea
                            autoComplete="off"
                            defaultValue={
                                record?.value
                                    ? record?.value
                                    : record?.defaultValue
                            }
                            onChange={(e) =>
                                onChange(record?.name, e.target.value)
                            }
                        />
                    </Form.Item>
                );

            default:
                return null;
        }
    }

    const toggleEditableField = (fieldName: any) => {
        if (editableFields.includes(fieldName)) {
            setEditableFields(
                editableFields.filter((field) => field !== fieldName)
            );
        } else {
            setEditableFields([...editableFields, fieldName]);
        }
    };

    const databasesColumns = [
        {
            title: 'Parameter Name',
            key: 'name',
            width: 450,
            render: (text: string, record: any) => {
                return (
                    record && (
                        <div>
                            <span>
                                <span
                                    className={
                                        styles.DatastoreSettingDbParametersName
                                    }
                                >
                                    {record?.name}
                                </span>
                                <InfoIcon
                                    info={<span>{record.description}</span>}
                                />
                            </span>
                            <br />
                            {getMinMaxDefaultValues(record)}
                        </div>
                    )
                );
            },
        },
        {
            title: 'Current Value',
            width: 650,
            key: 'value',
            render: (text: string, record: any) => {
                if (editableFields.includes(record?.name) && isEditMode) {
                    return renderInputField(record);
                } else {
                    return (
                        <div
                            className={styles.DatastoreSettingDbParametersEdit}
                        >
                            <span>
                                {getFormattedValues(
                                    record?.value || record?.defaultValue,
                                    false
                                )}
                            </span>
                            {!editableFields.includes(record?.name) &&
                                isEditMode && (
                                    <span
                                        className={
                                            styles.DatastoreSettingDbParametersEditIcon
                                        }
                                    >
                                        <EditOutlined
                                            onClick={() =>
                                                toggleEditableField(record.name)
                                            }
                                        />
                                    </span>
                                )}
                        </div>
                    );
                }
            },
        },
    ];

    const checkIsLegacyDataStore = async () => {
        if (dataStore?.dataStoreUuid === undefined) {
            return false;
        }
        try {
            setIsLoading(true);
            const isLegacy =
                await DbParameterGroupService.verifyLegacyDatastore(
                    dataStore?.dataStoreUuid
                );
            if (isLegacy.hasLegacyParameters()) {
                createLegacyGroup();
            } else {
                setIsDbParametersModalVisible(true);
            }
            setIsLoading(false);
        } catch (error) {
            showNotifications(
                false,
                'Error',
                'Failed to verify legacy datastore'
            );
            setIsLoading(false);
            return false;
        }
        return true;
    };

    const assignParameterGroup = async (
        groupSelected: any,
        dataStoreUUID: string
    ) => {
        try {
            await DbParameterGroupService.applyDbParameterGroup(
                groupSelected.uuid,
                dataStoreUUID
            );
            showNotifications(
                true,
                'Success',
                'Parameter group assigned successfully'
            );
            setIsAssignGroupModalVisible(false);
            setSelectedGroup(groupSelected);
        } catch (error) {
            setSelectedGroup(groupSelected);
            showNotifications(
                false,
                'Error',
                'Failed to assign parameter group'
            );
            setIsAssignGroupModalVisible(false);
        }
    };

    const syncGroup = async (groupId: string) => {
        setIsLoading(true);
        try {
            await DbParameterGroupService.syncDbParameterGroup(
                groupId,
                true,
                selectedGroup?.dbParameters
            );
            showNotifications(true, 'Success', 'Parameter group synced');
            setIsLoading(false);
        } catch (error) {
            showNotifications(false, 'Error', 'Failed to sync parameter group');
            setIsLoading(false);
        }
    };

    const createLegacyGroup = async () => {
        if (!dataStore?.dataStoreUuid) {
            return;
        }
        const leagacyGroup =
            await DbParameterGroupService.createLegacyDbParameterGroup(
                dataStore.dataStoreUuid
            );
    };

    const handleCancel = () => {
        dbParameterForm.resetFields();
        setIsDbParametersModalVisible(false);
    };

    const updateDbParametersWithGroupValues = (
        dbParameters: DbParameter[],
        groupParameters: { [key: string]: string }
    ): DbParameter[] => {
        if (!dbParameters || !groupParameters) return [];

        return dbParameters.map((param) => ({
            ...param,
            value: groupParameters[param.name] ?? param.value,
        }));
    };

    useEffect(() => {
        const parameters = dbParameters?.parameters;
        const groupDbParams = selectedGroup?.dbParameters;

        if (parameters && groupDbParams) {
            setDbParametersTableData(
                updateDbParametersWithGroupValues(parameters, groupDbParams)
            );
        }
    }, [dbParameters?.parameters, selectedGroup?.dbParameters]);

    const onSubmit = async (formData: any) => {
        const data = {
            name: formData.name,
            database_vendor: formData.databaseVendor,
            database_version: formData.databaseVersion,
            database_type: formData.configuration,
            description: formData.description,
            parameters: formData.dbParameters,
            data_stores: [dataStore?.dataStoreUuid],
        };

        try {
            await DbParameterGroupService.createDbParameterGroup(data);
            showNotifications(
                true,
                'Success',
                'Parameter group created successfully'
            );
            if (refresh) {
                refresh();
            }
            setIsDbParametersModalVisible(false);
        } catch (error) {
            showNotifications(
                false,
                'Error',
                'Failed to create parameter group'
            );
            setIsDbParametersModalVisible(false);
        }
    };

    const showNotifications = (
        type: boolean,
        message: string,
        description: string
    ) => {
        notification.open({
            message: message,
            description: description,
            icon: type ? (
                <CcxIconCheckCircleTwoTone twoToneColor="#52c41a" />
            ) : (
                <CcxIconCloseCircleTwoTone twoToneColor="#eb2f96" />
            ),
            duration: 4.5,
        });
    };

    return isLoading ? (
        <AppLoading />
    ) : (
        <>
            <ParameterGroupDisplay
                selectedGroup={selectedGroup}
                setVisible={setIsAssignGroupModalVisible}
                syncGroup={syncGroup}
                dataStore={dataStore}
                loading={isLoading}
                createLegacyGroup={checkIsLegacyDataStore}
            />
            <AppTable
                columns={databasesColumns}
                data={dbParametersTableData}
                rowKey="uniqueKey"
                pagination={{
                    hideOnSinglePage: true,
                }}
                expandable={false}
            />
            <AssignGroupModal
                form={form}
                visible={isAssignGroupModalVisible}
                dataStore={dataStore}
                setVisible={setIsAssignGroupModalVisible}
                onSubmit={assignParameterGroup}
            />
            <DbParametersModal
                setVisible={setIsDbParametersModalVisible}
                visible={isDbParametersModalVisible}
                handleCancel={handleCancel}
                form={dbParameterForm}
                operation={'create'}
                onSubmit={onSubmit}
            />
        </>
    );
}

export default DatastoreSettingDbParameters;
