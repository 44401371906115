import {
    Modal,
    Form,
    Row,
    Col,
    Input,
    Select,
    Divider,
    Tag,
    FormInstance,
    Spin,
    Button,
    Popover,
} from 'antd';
import styles from './DbParametersModal.module.less';
import AppTable from '../AppTable';
import { useAppSelector } from '../../redux/hooks';
import {
    getDatabaseConfigurationOptions,
    getDatabaseVendorOptions,
    getDatabaseVersionOptions,
} from '../../core/helpers';
import { useEffect, useState } from 'react';
import { CloseCircleOutlined, MinusCircleOutlined } from '@ant-design/icons';
import DbParameterGroupService from '../../services/DbParameterGroupService';
import { DBParameterDefaults } from '../../types/DBparameterDefaults';
import { DbParameterGroup } from '../../types/DbParameterGroup';
import UpdateDbParametersPopover from './UpdateDbParametersPopover';

interface DbParameterFormType {
    [x: string]: any;
    parameter: string;
    currentValue: string;
    datastores: string[];
    description?: string;
}

interface DbParametersModalProps {
    visible: boolean;
    onSubmit: (formData: any) => Promise<void>;
    setVisible: (visible: boolean) => void;
    handleCancel: () => void;
    operation: 'create' | 'edit';
    record?: Partial<DbParameterGroup>;
    form: FormInstance;
}

interface OptionType {
    value: string;
    label: string;
}

const DbParametersModal: React.FC<DbParametersModalProps> = ({
    visible,
    onSubmit,
    operation,
    setVisible,
    record,
    handleCancel,
    form,
}) => {
    const { deploymentOptions } = useAppSelector(
        (state) => state.deploymentOptions
    );

    const [isSubmitting, setIsSubmitting] = useState(false);
    const [dbVendor, setDbVendor] = useState<string>();
    const [dbVersion, setDbVersion] = useState<OptionType>();
    const [dbVersionOptions, setDbVersionOptions] = useState<OptionType[]>([]);
    const [dbConfigurationOptions, setDbConfigurationOptions] = useState<
        OptionType[]
    >([]);
    const [dbConfiguration, setDbConfiguration] = useState<OptionType>();
    const [tableForm] = Form.useForm();
    const [tableData, setTableData] = useState<DbParameterFormType[]>([]);
    const [datastoreList, setDatastoreList] = useState<string[]>([]);
    const [showPopover, setShowPopover] = useState(false);

    const databaseVendorOptions = getDatabaseVendorOptions(
        deploymentOptions?.getDatabaseVendors() || []
    );

    const handleDBVendorChange = async (value: string) => {
        const selectedDbVendor =
            deploymentOptions?.getDatabaseVendorByCode(value);
        setDbVendor(value);
        setDbVersion(undefined);
        setDbConfiguration(undefined);
        form.setFieldsValue({
            version: undefined,
            configuration: undefined,
        });

        if (selectedDbVendor?.name) {
            processDbVendorOptions(selectedDbVendor.name);
            processDbConfigurationsOptions(selectedDbVendor.name);
        }
        getDBParameters(value);
    };

    const processDbVendorOptions = (vendorName: string | undefined) => {
        if (vendorName) {
            const dbVersions = getDatabaseVersionOptions(
                deploymentOptions?.getDatabaseVendors(),
                vendorName
            );
            setDbVersionOptions(dbVersions);
        }
    };

    const processDbConfigurationsOptions = (vendorName: string | undefined) => {
        if (vendorName) {
            const dbConfigurations = getDatabaseConfigurationOptions(
                deploymentOptions?.getDatabaseVendors(),
                vendorName
            );
            setDbConfigurationOptions(dbConfigurations);
        }
    };

    const getDBParameters = async (value: string) => {
        const dbParametersDefaults: DBParameterDefaults =
            await DbParameterGroupService.getDefaultDbParameterGroup(value);
        const dbParameters: any[] = [];
        for (const [key, value] of Object.entries(
            dbParametersDefaults.getAllParameters()
        )) {
            dbParameters.push({
                parameter: key,
                currentValue: value.value,
                defaultValue: value.default_value,
                description: value.description,
            });
        }
        setTableData(dbParameters);
    };

    const processDbParametersForTable = (dbparameters: any) => {
        if (!dbparameters) return;
        const dbParametersForm: any[] = [];
        for (const [key, value] of Object.entries(dbparameters)) {
            dbParametersForm.push({
                parameter: key,
                currentValue: String(value ?? ''),
                defaultValue: String(value ?? ''),
            });
        }
        setTableData(dbParametersForm);
    };

    useEffect(() => {
        if (dbVersionOptions?.length === 1) {
            form.setFieldValue('version', dbVersionOptions[0].value);
            setDbVersion(dbVersionOptions[0]);
        }
        if (dbConfigurationOptions?.length === 1) {
            form.setFieldValue(
                'configuration',
                dbConfigurationOptions[0].value
            );
            setDbConfiguration(dbConfigurationOptions[0]);
        }
    }, [dbVersionOptions, dbConfigurationOptions, form]);

    useEffect(() => {
        if (record) {
            processDbVendorOptions(record?.databaseVendor);
            processDbConfigurationsOptions(record?.databaseVendor);
            processDbParametersForTable(record.dbParameters);

            const fieldsToSet = {
                groupName: record.name,
                description: record.description,
                vendor: record.databaseVendor,
                version: record.databaseVersion,
                configuration: record.databaseType,
            };

            form.setFieldsValue(fieldsToSet);
            if (record.dbParameters) {
                form.setFieldsValue(record.dbParameters);
            }
            setDatastoreList(record.dataStores || []);
            setDbVendor(record.databaseVendor);
        }
    }, [record, form, visible]);

    const handleOk = async () => {
        try {
            setIsSubmitting(true);
            const [formValues, tableValues] = await Promise.all([
                form.validateFields(),
                tableForm.validateFields(),
            ]);

            const submitData = {
                name: formValues.groupName,
                description: formValues.description,
                databaseVendor: formValues.vendor,
                databaseVersion: formValues.version,
                dataStores: datastoreList,
                configuration: formValues.configuration,
                dbParameters: tableValues,
            };
            await onSubmit(submitData);
            setVisible(false);
        } catch (error) {
            console.error('Form validation or submission failed:', error);
        } finally {
            setIsSubmitting(false);
        }
    };

    const columns = [
        {
            title: 'Parameter',
            dataIndex: 'parameter',
            width: 200,
        },
        {
            title: 'Current Value',
            dataIndex: 'currentValue',
            width: 300,
            render: (text: string, record: DbParameterFormType) => (
                <Form.Item
                    className={styles.DbParameterFormInput}
                    name={record.parameter}
                    initialValue={text}
                    rules={[{ required: true, message: 'Required' }]}
                >
                    <Input className={styles.DbParameterFormInput} />
                </Form.Item>
            ),
        },
        {
            title: 'Default Value',
            dataIndex: 'defaultValue',
            width: 200,
            render: (text: string, record: DbParameterFormType) => (
                <div>{record.defaultValue}</div>
            ),
        },
        {
            title: 'Description',
            dataIndex: 'description',
        },
    ];

    return (
        <Modal
            title={
                operation === 'create'
                    ? 'Create new DB parameter group'
                    : 'Edit DB parameter group'
            }
            open={visible}
            footer={
                <>
                    <Button onClick={handleCancel}>Cancel</Button>
                    {operation === 'create' && (
                        <Button type="primary" onClick={handleOk}>
                            Create
                        </Button>
                    )}
                    {operation === 'edit' && (
                        <UpdateDbParametersPopover
                            handleCancel={handleCancel}
                            handleOk={handleOk}
                        />
                    )}
                </>
            }
            onCancel={() => setVisible(false)}
            width={1100}
            confirmLoading={isSubmitting}
            maskClosable={!isSubmitting}
        >
            <Spin spinning={isSubmitting}>
                <Form
                    layout="vertical"
                    className={styles.DbParameterForm}
                    form={form}
                >
                    <Row gutter={[16, 16]}>
                        <Col span={operation === 'create' ? 24 : 12}>
                            <Row gutter={[16, 16]}>
                                <Col span={operation === 'create' ? 4 : 8}>
                                    <Form.Item
                                        label="Group name"
                                        name="groupName"
                                        rules={[
                                            {
                                                required: true,
                                                message:
                                                    'Group name is required',
                                            },
                                        ]}
                                    >
                                        <Input placeholder="Name your group" />
                                    </Form.Item>
                                </Col>
                                <Col span={operation === 'create' ? 9 : 16}>
                                    <Form.Item
                                        label="Description"
                                        name="description"
                                    >
                                        <Input placeholder="Enter a description" />
                                    </Form.Item>
                                </Col>
                                <Col span={operation === 'create' ? 4 : 8}>
                                    <Form.Item
                                        label="Vendor"
                                        name="vendor"
                                        rules={[
                                            {
                                                required: true,
                                                message: 'Vendor is required',
                                            },
                                        ]}
                                    >
                                        <Select
                                            disabled={operation === 'edit'}
                                            options={databaseVendorOptions}
                                            onChange={handleDBVendorChange}
                                            value={dbVendor}
                                        />
                                    </Form.Item>
                                </Col>
                                <Col span={operation === 'create' ? 3 : 5}>
                                    <Form.Item
                                        label="Version"
                                        name="version"
                                        rules={[
                                            {
                                                required: true,
                                                message: 'Version is required',
                                            },
                                        ]}
                                    >
                                        <Select
                                            disabled={operation === 'edit'}
                                            options={dbVersionOptions}
                                            value={dbVersion?.value}
                                        />
                                    </Form.Item>
                                </Col>
                                <Col span={operation === 'create' ? 4 : 11}>
                                    <Form.Item
                                        label="Configuration"
                                        name="configuration"
                                        rules={[
                                            {
                                                required: true,
                                                message:
                                                    'Configuration is required',
                                            },
                                        ]}
                                    >
                                        <Select
                                            disabled={operation === 'edit'}
                                            options={dbConfigurationOptions}
                                            value={dbConfigurationOptions}
                                        />
                                    </Form.Item>
                                </Col>
                            </Row>
                        </Col>
                        <Col span={operation === 'edit' ? 12 : 0}>
                            Associated data stores
                            <Row gutter={[16, 16]}>
                                {datastoreList?.map(
                                    (dataStore: string, index: number) => (
                                        <Col key={dataStore}>
                                            <Tag
                                                color={'green'}
                                                closable={true}
                                                className={
                                                    styles.DbParametersModalTag
                                                }
                                                closeIcon={
                                                    <MinusCircleOutlined />
                                                }
                                                onClose={() => {
                                                    const newStoreList = [
                                                        ...datastoreList,
                                                    ];
                                                    newStoreList.splice(
                                                        index,
                                                        1
                                                    );
                                                    setDatastoreList(
                                                        newStoreList
                                                    );
                                                }}
                                            >
                                                <CloseCircleOutlined />{' '}
                                                {`datastore-${index + 1}`}
                                            </Tag>
                                        </Col>
                                    )
                                )}
                            </Row>
                        </Col>
                    </Row>
                </Form>
                <Divider />
                <Form layout="vertical" className={styles.DbParameterForm}>
                    <Row>
                        <Col span={12}>
                            <Form.Item label="Search" name="search">
                                <Input placeholder="Search" />
                            </Form.Item>
                        </Col>
                    </Row>
                </Form>
                <Form form={tableForm}>
                    <AppTable
                        columns={columns}
                        data={tableData}
                        rowKey="parameter"
                        pagination={false}
                        expandable={false}
                    />
                </Form>
            </Spin>
        </Modal>
    );
};

export default DbParametersModal;
